import querystring from 'querystring';
import Cookies from 'js-cookie';
import { sha512 } from 'js-sha512';

import { UserInfo } from '../type';
import { cookieDomain } from './domain';
import { getProviderConfig } from '../constants/providerConfig';
export const ONE_TIME_TOKEN_KEY = '_token';
export interface CookieToken {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}
export interface CookieConfig {
  domain?: string;
  expires?: number;
  secure?: boolean;
}
const cookieBaseConfig = {
  domain: cookieDomain,
  secure: !(isDev() || isLocal()),
};

export function getUrlQueryParams(search = window.location.search): {
  [key: string]: string | string[] | undefined;
} {
  try {
    if (search.startsWith('?')) {
      search = search.substring(1);
    }
    return querystring.parse(search) || {};
  } catch (e) {
    return {};
  }
}

export function setToken(
  { accessToken, refreshToken, idToken }: CookieToken,
  config: CookieConfig = {}
) {
  const finalConfig = {
    ...cookieBaseConfig,
    ...config,
  };
  Cookies.set('accessToken', accessToken, finalConfig);
  Cookies.set('refreshToken', refreshToken, finalConfig);
  Cookies.set('idToken', idToken, finalConfig);
  // Cookies.set('idpIdToken', idpIdToken, finalConfig);
}

export function getToken(): CookieToken {
  return {
    accessToken: Cookies.get('accessToken') || '',
    refreshToken: Cookies.get('refreshToken') || '',
    idToken: Cookies.get('idToken') || '',
  };
}

export function setUser(user: Partial<UserInfo>, config: CookieConfig = {}) {
  const finalConfig = {
    ...cookieBaseConfig,
    ...config,
  };
  setCookieObj('user', user, finalConfig);
}

export function getUser(): Partial<UserInfo> | null {
  return getCookieObj('user');
}
export function setLoginFlag(config: CookieConfig = {}) {
  const finalConfig = {
    ...cookieBaseConfig,
    ...config,
  };
  Cookies.set('loginTime', Date.now(), finalConfig);
}
export function setCookieObj(key: string, obj: any, config: CookieConfig = {}) {
  try {
    Cookies.set(key, JSON.stringify(obj), config);
  } catch (e) {}
}

export function getCookieObj(key: string) {
  try {
    return JSON.parse(Cookies.get(key));
  } catch (e) {
    return null;
  }
}

export function clearToken() {
  Cookies.remove('accessToken', cookieBaseConfig);
  Cookies.remove('refreshToken', cookieBaseConfig);
  Cookies.remove('idToken', cookieBaseConfig);
}

export function clearUser() {
  Cookies.remove('user', cookieBaseConfig);
}

export function clearAllAuth() {
  clearToken();
  clearUser();
}

// return 1, 2, 3, 4 (strong)
export function testPasswordStrength(pwd = '') {
  let strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  let mediumRegex = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})'
  );
  if (strongRegex.test(pwd)) {
    return 4;
  }
  if (mediumRegex.test(pwd)) {
    if (pwd.length >= 6) {
      return 3;
    } else {
      return 2;
    }
  }
  return 1;
}

export function testInviteCode(code: string) {
  const allowed = [
    '92f3bf45390c5bfc4fa03f9378486bec6395918e35f412faf5a78da96fddc1c198cd211d9fa1c5ae0b658dabc67788bf3e8d8835f3e7495e8162550de0dcf056',
    '498e2c4988a239aba05e9fdee2a307568facaf66cfcea06fe0f3631fb6a6ef066f6d2901473fe86591938689031e9bd933a259023ab0cec6f21821ec5bfaae28',
    '620c5756739d618c3b98e6d933ff9cb423de9f53c99002f2a5208b87e6a388d7509d75119e15561afdb551ddb469ad51b4ebda4cd84ed122ef130ec2448a9e77',
    'fdecac90672d1743d5d3616f774cf0562d2e277007e8a96e8a9be89aacd5fed6a50bf3ad6f1ba5fa2fb0af20b020a54421f87139081406d0d0a62972bf519ce7',
    'c19b36cc882f011f7c065215ebed135cf2df9956068c12bb5a80b2bbd091381eb6db4c086d548f8aede74c5458c8787956029bd567f645a9832444337119c8f1',
  ];
  const salt = 'xaareawdfgagpaw';
  const res = sha512(code + salt);
  return allowed.includes(res);
}

export function isProd() {
  return process.env.REACT_APP_BUILD_ENV === 'prod';
}
export function isDev() {
  return process.env.REACT_APP_BUILD_ENV === 'dev';
}
export function isLocal() {
  return process.env.REACT_APP_BUILD_ENV === 'local';
}
export function isFromTidy3d() {
  return isFromApp('tidy3d') || isFromApp('rf');
}
/**
 * RF is a sub-app of Tidy3D
 */
export function isFromTidy3d_RF() {
  return isFromApp('rf');
}
export function isFromFlow360() {
  return isFromApp('flow360');
}

export function isFromAdmin() {
  return isFromApp('admin');
}
export function isFromLog() {
  return isFromApp('logapp');
}
export function isFromInternal() {
  return isFromAdmin() || isFromLog();
}
/**
 * Parse and extract ${app} from URL
 * @returns {string}
 */
export function getAppNameFromUrl() {
  if (isFromTidy3d()) {
    return 'tidy3d';
  } else if (isFromAdmin()) {
    return 'admin';
  } else if (isFromFlow360()) {
    return 'flow360';
  } else if (isFromLog()) {
    return 'logapp';
  }
}
export function getAppTitleFromUrl() {
  if (isFromTidy3d()) {
    return 'Tidy3D - FDTD for Electromagnetic Simulation | Flexcompute Inc.';
  } else if (isFromFlow360()) {
    return 'Flow360 - The revolutionary CFD solver | Flexcompute Inc.';
  }
  return 'Flexcompute Inc.';
}
export function getAppDescFromUrl() {
  if (isFromTidy3d()) {
    return 'Faster, Larger Simulations - A new solver dedicated to next-generation chips has yielded a simulation breakthrough';
  } else if (isFromFlow360()) {
    return 'A Scalable CFD Solver for Better Product Design - Flow360 Solver is a revolutionary CFD solver based on a breakthrough computing architecture that results in scalability of accurate aerodynamic simulations at unprecedented speeds.';
  }
  return 'Flexcompute Inc.';
}
export function isFromApp(appName: string) {
  const params = getUrlQueryParams();
  const ref = decodeURIComponent((params.ref as string) || '');
  // const app = params.app || '';
  // const isFromApp =
  //   ref.indexOf(`${appName}.simulation.cloud`) > -1 ||
  //   app.toLowerCase() === appName;
  // const isFromApp = ref.indexOf(`${appName}.simulation.cloud`) > -1;
  const reg = new RegExp(
    appName + '\\.((dev\\-)|(uat\\-))??simulation\\.cloud',
    'i'
  );
  const isFromApp = reg.test(ref);
  return isFromApp;
}
export function isFromClientHost(
  clientHost: string,
  host = window.location.host
) {
  const reg = new RegExp(
    `^${clientHost}-.*\\.((dev-)|(uat-))??simulation\\.cloud$`,
    'i'
  );
  const isFromClientHost = reg.test(host);
  return isFromClientHost;
}
export function isFromAnduril() {
  return isFromClientHost('anduril');
}
export function jumpToAnduril() {
  const config = getProviderConfig('anduril');
  if (config) {
    window.location.href = config.loginUrl;
  }
}
// export function getDefaultAppHost(appName) {
//   appName = getAppNameFromUrl() || 'flow360';
//   if (isFromApp(appName)) {
//     return `${getDefaultHostPrefix()}${appName}.simulation.cloud`;
//   }
// }

// export function getDefaultHostPrefix() {
//   const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
//   if (BUILD_ENV === 'prod') {
//     return '';
//   } else if (BUILD_ENV === 'uat') {
//     return 'uat-';
//   }
//   return 'dev-';
// }
// const PAGE_TITLE_MAP = {
//   '/login': {
//     title: 'Sign In',
//   },
//   '/reset_password': {
//     title: 'Reset Password',
//   },
//   '/signup': {
//     title: 'Sign Up',
//   },
// };
export function initDocTitleAndDesc() {
  const title = getAppTitleFromUrl();
  const desc = getAppDescFromUrl();
  // const pageTitle = PAGE_TITLE_MAP[location?.pathname]?.title || '';
  const headTitleEle = document.getElementById('headTitle') as HTMLTitleElement;
  const metaTitleEle = document.getElementById('metaTitle') as HTMLMetaElement;
  const metaDescEle = document.getElementById('metaDesc') as HTMLMetaElement;
  headTitleEle.innerText = title;
  metaTitleEle.content = title;
  metaDescEle.content = desc;
}
export function encodeUrlJson(obj: any): string {
  try {
    return encodeURIComponent(JSON.stringify(obj)) || '';
  } catch (e) {
    console.error(e);
    return '';
  }
}
export function decodeUrlJson(str: string) {
  try {
    return JSON.parse(decodeURIComponent(str)) || {};
  } catch (e) {
    console.error(e);
    return {};
  }
}
export function initHubspotForm(formId: string, formData: any) {
  const iframeName = `${Date.now()}_form`;

  const form = document.createElement('form');
  form.id = formId;
  form.style.display = 'none';
  form.target = iframeName;
  Object.entries(formData ?? {}).forEach(([name, value]) => {
    const input = document.createElement('input');
    input.type = 'text';
    input.name = name;
    input.value = value as string;
    form.appendChild(input);
  });
  const submitBtn = document.createElement('button');
  submitBtn.type = 'submit';
  form.appendChild(submitBtn);

  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'none';
  iframe.width = '0';
  iframe.height = '0';
  iframe.name = iframeName;
  // iframe.srcdoc = div.innerHTML;
  document.body.appendChild(iframe);
  document.body.appendChild(form);
  return {
    submit: () => {
      clearHubspotCache();
      setTimeout(() => {
        submitBtn.click();
        document.body.removeChild(iframe);
        document.body.removeChild(form);
      }, 1000);
    },
  };
}
export function clearHubspotCache() {
  localStorage.removeItem('li_submission');
}
export function addTokenToUrl(url: string, token: string) {
  const resultUrl = new URL(url);
  resultUrl.searchParams.set(ONE_TIME_TOKEN_KEY, token);
  return resultUrl.href;
}
